
import React from 'react';
  
const Contact = () => {
  return (
    <div>
      <h1>IM GOING TO BE THE VERY BEST
        THAT NO ONE EVER WASSSSSS
      </h1>
      <h2>to catch them is my real test!!
      </h2>
      <h3>TO TRAIN THEM IS MY CAUSEEEe</h3>
    </div>
  );
};
  
export default Contact;

